.sidebar-logo h1.subtitle {
	color: #fff;
}

.is-sidebar-menu {
	padding: 2.5rem;
	background: #4a4a4a;
	width: 250px !important;
	flex: none;
}

.is-sidebar-menu li a {
	color: white;
}

.columns.is-fullheight {
	height: calc(100vh + .75rem);
	display: flex;
	flex-direction: row;
	justify-content: stretch;
}

.columns.is-fullheight .column {
	overflow-y: auto;
}

.is-main-content {
	background: #efeff1;
	padding-right: 1.5rem;
	padding-top: 2.5rem;
}

.is-title-bar {
	background-color: #fff;
	margin-left: -0.75rem;
	margin-right: -0.75rem;
	margin-bottom: 1rem;
	padding: 1rem;

	background-image: linear-gradient(to right, #f4f4f4, #f7f7f7, #fbfbfb, #ffffff, #ffffff, #ffffff, #ffffff);
}

.is-detail-container {
	max-width: 768px;
}

.is-mobile-nav-toggle {
	float: right;
	height: 70px;
	font-size: 25px;
	display: flex;
	align-items: center;
}

.section-container {
	border: solid 1px #888;
	padding: 15px;
	border-radius: 5px;
	background-color: #f7f7f7;
}

.border-bottom {
	border-bottom: solid 1px black;
}

@media screen and (max-width: 768px) {
	.is-main-content {
		padding-left: 1.5rem;
	}

	.columns.is-fullheight {
		height: calc(100vh + 1.5rem);
	}
}